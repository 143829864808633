import React from "react";
import connect from "react-redux/lib/connect/connect";
import Map from "./map";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state) => {
    return {
        currentLocation: state.location,
        business: state.business,
        filters: state.filters,
        clickedBusiness: state.pin.clickedBusiness,
        hoveredBusiness: state.pin.hoveredBusiness,
        fetchBusiness: state.fetchBusiness,
    };
};

const CurrentLocationMap = withRouter(connect(mapStateToProps)(Map));

export default CurrentLocationMap;
