import React from "react";
import Business from "./business";
import connect from "react-redux/lib/connect/connect";
import "../css/business-list.css";

const mapStateToProps = (state) => {
    return {
        business: state.business,
        filters: state.filters,
        location: state.location,
        fetchBusiness: state.fetchBusiness,
        clickedBusiness: state.pin.clickedBusiness,
        hoveredBusiness: state.pin.hoveredBusiness,
        scroll: state.scroll,
    };
};

const BusinessToShow = connect(mapStateToProps)(Business);

export default BusinessToShow;
