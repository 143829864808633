function twentyFourToTwelve(twentyFour) {
    const hh = Math.floor(twentyFour / 100);
    const mm = twentyFour % 100;

    if (mm === 0 && (hh === 0 || hh === 24)) {
        return "midnight";
    }

    let hhAdj = hh > 12 ? hh - 12 : hh;
    // 0:30 am looks weird, want 12:30 am
    if (hhAdj === 0) {
        hhAdj = 12;
    }

    const mmAdj = mm < 10 ? "0" + mm : mm;
    const suffix = hh < 12 ? "am" : "pm";
    return hhAdj + ":" + mmAdj + "" + suffix;
}

export function findRanges(daysOfWeek) {
    let ranges = [];
    let currIndex = 0;

    while (currIndex < daysOfWeek.length) {
        let startIndex = currIndex;
        while (
            currIndex < daysOfWeek.length - 1 &&
            daysOfWeek[currIndex] + 1 === daysOfWeek[currIndex + 1]
        ) {
            currIndex++;
        }
        ranges.push([daysOfWeek[startIndex], daysOfWeek[currIndex]]);
        currIndex++;
    }

    // This is the case for ex. 1,2,6,7 ie sun, mon, fri, sat.
    // We want to wrap around such that the output is fri - mon instead of sun-mon, fri-sat
    if (
        daysOfWeek[0] === 1 &&
        daysOfWeek[daysOfWeek.length - 1] === 7 &&
        ranges.length > 1
    ) {
        let first = ranges[0];
        let last = ranges[ranges.length - 1];
        let combined = [last[0], first[1]];

        let updatedRanges = [];
        // everything minus first and last as they are getting combined
        for (let i = 1; i < ranges.length - 1; i++) {
            updatedRanges.push(ranges[i]);
        }
        updatedRanges.push(combined);
        return updatedRanges;
    }

    return ranges;
}

function dayName(dayNum) {
    let dayName = "";
    switch (dayNum) {
        case 1:
            dayName = "Sun";
            break;
        case 2:
            dayName = "Mon";
            break;
        case 3:
            dayName = "Tue";
            break;
        case 4:
            dayName = "Wed";
            break;
        case 5:
            dayName = "Thurs";
            break;
        case 6:
            dayName = "Fri";
            break;
        case 7:
            dayName = "Sat";
            break;
    }
    return dayName;
}

function dayNames(daysOfWeek) {
    let daysOfWeekFormatted = [];
    let ranges = findRanges(daysOfWeek);
    ranges.forEach((range) => {
        if (range[0] === range[1]) {
            daysOfWeekFormatted.push(dayName(range[0]));
        } else if (range[0] === 1 && range[1] === 7) {
            daysOfWeekFormatted.push("Everyday");
        } else {
            daysOfWeekFormatted.push(
                dayName(range[0]) + "-" + dayName(range[1])
            );
        }
    });
    return daysOfWeekFormatted.join(", ");
}

export function getFormattedTimeSlotsList(timeSlots) {
    let timings = [];

    if (!timeSlots) {
        return "";
    }

    timeSlots
        .sort((timeSlot1, timeSlot2) => {
            if (timeSlot1.DaysOfWeek[0] !== timeSlot2.DaysOfWeek[0]) {
                return timeSlot1.DaysOfWeek[0] - timeSlot2.DaysOfWeek[0];
            }
            // if days start at same, use start time
            return timeSlot1.StartTime - timeSlot2.StartTime;
        })
        .forEach((timeslot) => {
            const startTime = timeslot.StartTime;
            const endTime = timeslot.EndTime;
            const days = timeslot.DaysOfWeek;

            const startTime12 = twentyFourToTwelve(startTime);
            const endTime12 = twentyFourToTwelve(endTime);
            const dayNamesStr = dayNames(days);

            timings.push([dayNamesStr, startTime12 + " - " + endTime12]);
        });

    return timings;
}

export function getFormattedStrippedTimeSlots(timeSlot) {
    const startTime = timeSlot.startTime;
    const endTime = timeSlot.endTime;

    const startTime12 = twentyFourToTwelve(startTime);
    const endTime12 = twentyFourToTwelve(endTime);

    return startTime12 + " - " + endTime12;
}

export function compareTimeSlots(timeSlots1, timeSlots2) {
    let minTimeSlot1 = 100;
    let minTimeSlot2 = 100;

    timeSlots1.forEach((timeSlot1) => {
        minTimeSlot1 = Math.min(minTimeSlot1, timeSlot1.DaysOfWeek[0]);
    });

    timeSlots2.forEach((timeSlot2) => {
        minTimeSlot2 = Math.min(minTimeSlot2, timeSlot2.DaysOfWeek[0]);
    });

    return minTimeSlot1 - minTimeSlot2;
}
