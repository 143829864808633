import {
    ADD_BUSINESS,
    CATEGORY_FILTER,
    CHANGE_BOUNDS,
    CHANGE_LOCATION,
    CLEAR_FILTERS,
    DAY_FILTER,
    INIT_BUSINESS,
    INIT_MAP,
    NEIGHBORHOOD_FILTER,
    RATING_FILTER,
    LOADED_BUSINESS,
    REQUEST_BUSINESS,
    TIME_FILTER,
    CLICK_PIN,
    SEARCH_FILTER,
    SLIDING_MENU_TOGGLE,
    HOVER_PIN,
    SAVE_SCROLL_POSITION,
    RESET_MAP,
    TOGGLE_MODAL,
    CLOSE_NOTIFICATION,
    MARKET_FILTER,
    CHANGE_MARKET,
} from "./actionTypes";
import fetch from "node-fetch";
import { decrypt } from "../utils/decryptor";

export function initializeMap() {
    return {
        type: INIT_MAP,
    };
}

export function resetMap() {
    return {
        type: RESET_MAP,
    };
}

export function changeLocation(lat, lon) {
    console.log("change location called");
    return {
        type: CHANGE_LOCATION,
        lat: lat,
        lon: lon,
    };
}

export function changeMarket(market) {
    console.log("change market called to" + market);
    return {
        type: CHANGE_MARKET,
        market: market,
    };
}

export function changeBounds(sw, ne) {
    return {
        type: CHANGE_BOUNDS,
        sw: sw,
        ne: ne,
    };
}

export function initBusiness(business) {
    return {
        type: INIT_BUSINESS,
        business: business,
    };
}

export function addBusiness(business) {
    return {
        type: ADD_BUSINESS,
        business: business,
    };
}

function requestBusiness() {
    return {
        type: REQUEST_BUSINESS,
    };
}

function loadedBusiness() {
    return {
        type: LOADED_BUSINESS,
    };
}

export function updateMarket(market) {
    return function (dispatch) {
        dispatch(updateMarketFilter(market));
        dispatch(changeMarket(market));
        dispatch(requestBusiness());
        return fetch("https://api.dealiem.com/active_business_gzip/" + market)
            .then(
                (response) => {
                    return response.text();
                },
                (error) => alert("failed to fetch deals!")
            )
            .then((text) => {
                if (text) {
                    const d = decrypt(text);
                    const json = JSON.parse(d);
                    dispatch(addBusiness(json));
                    dispatch(loadedBusiness());
                }
            });
    };
}

export function updateDayFilter(day) {
    console.log("updated day filter!, day = " + day);
    return {
        type: DAY_FILTER,
        day: day,
    };
}

export function updateTimeFilter(time) {
    console.log("updated time filter!, time = " + time);
    return {
        type: TIME_FILTER,
        time: time,
    };
}

export function updateRatingFilter(rating) {
    return {
        type: RATING_FILTER,
        rating: rating,
    };
}

export function updateNeighborhood(neighborhood) {
    console.log("neighborhood = " + neighborhood);
    return function (dispatch) {
        let lat = 43.6532;
        let lon = -79.3832;
        switch (neighborhood) {
            case "chinatown":
                lat = 43.6509;
                lon = -79.3972;
        }
        console.log("new lat = " + lat);
        dispatch(changeLocation(lat, lon));
        dispatch(updateNeighborhoodFilter(neighborhood));
    };
}

function updateNeighborhoodFilter(neighborhood) {
    console.log("neighborhood filter changed");
    return {
        type: NEIGHBORHOOD_FILTER,
        neighborhood: neighborhood,
    };
}

export function updateCategoryFilter(category) {
    return {
        type: CATEGORY_FILTER,
        category: category,
    };
}

export function updateMarketFilter(market) {
    return {
        type: MARKET_FILTER,
        market: market,
    };
}

export function updateSearchFilter(searchText) {
    return {
        type: SEARCH_FILTER,
        search: searchText,
    };
}

export function clearFilters() {
    return {
        type: CLEAR_FILTERS,
    };
}

export function clickPin(business) {
    return {
        type: CLICK_PIN,
        business: business,
    };
}

export function hoverPin(business) {
    return {
        type: HOVER_PIN,
        business: business,
    };
}

export function slidingMenuToggle(isOpen) {
    return {
        type: SLIDING_MENU_TOGGLE,
        isOpen: isOpen,
    };
}

export function saveScrollPosition(documentPosition, listPosition) {
    return {
        type: SAVE_SCROLL_POSITION,
        documentPosition: documentPosition,
        listPosition: listPosition,
    };
}

export function toggleModal(open) {
    return {
        type: TOGGLE_MODAL,
        open: open,
    };
}

export function closeNotification() {
    return {
        type: CLOSE_NOTIFICATION,
    };
}
