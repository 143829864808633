import ReactSlidingPane from "react-sliding-pane";
import React from "react";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { slidingMenuToggle } from "../store/actionCreator";
import connect from "react-redux/lib/connect/connect";

const mapStateToProps = (state) => {
    return {
        isOpen: state.slidingMenu.isOpen,
    };
};

class SlidingMenu extends React.Component {
    disableMenu() {
        this.props.dispatch(slidingMenuToggle(false));
    }

    render() {
        return (
            <ReactSlidingPane
                hideHeader={true}
                //closeIcon={<div className={"fas fa-times"} />}
                className="sliding-menu"
                overlayClassName="sliding-menu"
                isOpen={this.props.isOpen}
                // subtitle='Optional subtitle.'
                width="250px"
                onRequestClose={() => this.disableMenu()}
            >
                <div className="sliding-menu-content">
                    <div className="sliding-menu-item sliding-menu-about">
                        <a href="/about">ABOUT</a>
                    </div>
                    <div className="sliding-menu-item sliding-menu-for-biz">
                        <a href="/about/business.html">FOR BUSINESS</a>
                    </div>
                    <div className="sliding-menu-item sliding-menu-blog">
                        <a href="/blog">BLOG</a>
                    </div>
                </div>
            </ReactSlidingPane>
        );
    }
}

export default connect(mapStateToProps)(SlidingMenu);
