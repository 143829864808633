export function equalTimeSlots(timeSlots1, timeSlots2) {
    if (timeSlots1.length !== timeSlots2.length) {
        return false;
    }
    let difference = timeSlots1.filter((timeSlot1) => {
        let matches = false;
        timeSlots2.forEach((timeSlot2) => {
            matches = matches || equalTimeSlot(timeSlot1, timeSlot2);
        });
        return !matches;
    });
    return difference.length === 0;
}

function equalTimeSlot(timeSlot1, timeSlot2) {
    return (
        timeSlot1.StartTime === timeSlot2.StartTime &&
        timeSlot1.EndTime === timeSlot2.EndTime &&
        equalDaysOfWeek(timeSlot1.DaysOfWeek, timeSlot2.DaysOfWeek)
    );
}

function equalDaysOfWeek(daysOfWeek1, daysOfWeek2) {
    if (daysOfWeek1.length !== daysOfWeek2.length) {
        return false;
    }
    let difference = daysOfWeek1.filter((day) => {
        return !daysOfWeek2.includes(day);
    });
    return difference.length === 0;
}
