import * as React from "react";
import Carousel from "nuka-carousel";
import { isMobile } from "react-device-detect";

class ImagesCarousel extends React.Component {
    //handleOnDragStart = (e) => e.preventDefault();
    render() {
        if (!this.props.images || this.props.images.length === 0) {
            return <div />;
        }

        let imgs = [];
        this.props.images.forEach((img, index) => {
            imgs.push(
                <img
                    src={img.Url}
                    className="carousel-img"
                    onClick={() => this.props.onImgClick(index)}
                    alt={this.props.businessName + "-" + index}
                />
            );
        });

        return (
            <Carousel
                slidesToShow={isMobile ? 2.5 : 3.5}
                withoutControls={true}
                wrapAround={true}
            >
                {imgs}
            </Carousel>
        );
    }
}

export default ImagesCarousel;
