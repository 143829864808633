import React from "react";
import ReactMapGL, {
    FullscreenControl,
    GeolocateControl,
    Layer,
    NavigationControl,
    Popup,
    Source,
} from "react-map-gl";
import {
    changeBounds,
    changeLocation,
    clickPin,
    hoverPin,
} from "../store/actionCreator";
import { filterBusiness } from "../utils/filterBusiness";
import _ from "lodash";
import { Link } from "react-router-dom";
import markerIcon from "../images/marker-img.svg";
import markerIconGrey from "../images/marker-img-grey.svg";
import { getBusinessIdFromPath } from "../utils/commonUtils";
import { isMobile } from "react-device-detect";
import * as queryString from "query-string";
import noDealsImg from "../images/no-deals-researching.png";

const geolocateStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    margin: 10,
};

const navStyle = {
    position: "absolute",
    top: 36,
    left: 0,
    margin: 10,
};

const fullscreenStyle = {
    position: "absolute",
    top: 101,
    left: 0,
    margin: 10,
};

const defaultZoomDesktop = 12;
const defaultZoomMobile = 11;

class Map extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            viewport: {
                zoom: isMobile ? defaultZoomMobile : defaultZoomDesktop,
            },
            autoGeolocate:
                !this.getDetailBusinessId() &&
                !queryString.parse(document.location.search)["disableGeo"],
        };
        this.onBoundsChangeThrottled = _.debounce(
            (sw, ne) => this.onBoundsChange(sw, ne),
            100
        );
    }

    componentDidMount() {
        if (!this.mapRef) {
            return;
        }
        this.forceMapBoundsUpdate();
    }

    // this will handle the map view changes when switching
    // between main page and the detailed page
    // more details:
    componentWillReceiveProps(nextProps) {
        const currMarket = this.props.currentLocation.market;
        const nextMarket = nextProps.currentLocation.market;
        const currBusinessId = this.getDetailBusinessId();
        const nextBusinessId = this.getDetailBusinessId(nextProps);
        const viewport = this.state.viewport;

        if (currMarket !== nextMarket) {
            this.forceMapUpdate = false;
            viewport["latitude"] = nextProps.currentLocation.lat;
            viewport["longitude"] = nextProps.currentLocation.lon;
            viewport.zoom = this.state.mapProps
                ? this.state.mapProps.zoom
                : isMobile
                ? defaultZoomMobile
                : defaultZoomDesktop;
            this.onViewportChange(viewport);
            this.forceMapBoundsUpdate();
            return;
        }

        if (nextBusinessId) {
            if (nextBusinessId !== currBusinessId) {
                this.props.dispatch(clickPin(null));
                this.props.dispatch(hoverPin(null));
                const business = this.getDetailBusiness(nextBusinessId);

                // saving the current view states to memory
                // so that when the user returns back from the
                // detailed view, they can get back to the origin
                this.setState({
                    mapProps: {
                        zoom: viewport.zoom,
                        lat: viewport.latitude,
                        lon: viewport.longitude,
                    },
                });

                viewport["latitude"] = business.Lat;
                viewport["longitude"] = business.Lon;
                viewport.zoom = 13;
                this.onViewportChange(viewport);
            } else {
                console.log("update while staying on detailed page");
            }
        } else {
            if (currBusinessId) {
                this.props.dispatch(clickPin(null));
                this.props.dispatch(hoverPin(null));

                // if the state is stored in memory, use those
                // else use the default ones
                const location = this.getLocation(null);
                viewport["latitude"] = this.state.mapProps
                    ? this.state.mapProps.lat
                    : location.lat;
                viewport["longitude"] = this.state.mapProps
                    ? this.state.mapProps.lon
                    : location.lon;
                viewport.zoom = this.state.mapProps
                    ? this.state.mapProps.zoom
                    : isMobile
                    ? defaultZoomMobile
                    : defaultZoomDesktop;

                this.setState({
                    autoGeolocate: !this.state.mapProps,
                    mapProps: null,
                });

                this.onViewportChange(viewport);
            } else {
                console.log("update while staying on main page");
                if (!this.forceMapUpdate) {
                    this.forceMapBoundsUpdate();
                }
            }
        }
    }

    onBoundsChange(sw, ne) {
        this.props.dispatch(changeBounds(sw, ne));
    }

    // forces a new frame with the new layout bounds
    // this is needed to fix the case when initial layout
    // is not updating the pins and businesses according to
    // the new bounds
    forceMapBoundsUpdate() {
        requestAnimationFrame(() => {
            if (this.mapRef) {
                const bounds = this.mapRef.getMap().getBounds();
                let sw = { lat: bounds._sw.lat, lon: bounds._sw.lng };
                let ne = { lat: bounds._ne.lat, lon: bounds._ne.lng };
                this.onBoundsChangeThrottled(sw, ne);
                this.forceMapUpdate = true;
            }
        });
    }

    onViewportChangeHandler = function (viewport) {
        this.setState({ viewport: viewport });
        if (this.mapRef) {
            const bounds = this.mapRef.getMap().getBounds();
            let sw = { lat: bounds._sw.lat, lon: bounds._sw.lng };
            let ne = { lat: bounds._ne.lat, lon: bounds._ne.lng };
            this.onBoundsChangeThrottled(sw, ne);
        }
    };

    onViewportChange = (viewport) => {
        const { width, height, ...etc } = viewport;
        this.onViewportChangeHandler(etc);
    };

    onPinClick = (business) => {
        // detail page shouldn't allow pin click
        if (this.getDetailBusinessId()) {
            return;
        }
        this.props.history.push("/");
        if (window.document.getElementsByClassName("business-list")[0]) {
            window.document.getElementsByClassName(
                "business-list"
            )[0].scrollTop = 0;
        }
        this.props.dispatch(clickPin(business));
    };

    onPinHover = (business) => {
        this.props.dispatch(hoverPin(business));
    };

    _renderPopup() {
        const business = this.props.clickedBusiness;
        return (
            business && (
                <Popup
                    tipSize={3}
                    anchor="top"
                    longitude={business.Lon}
                    latitude={business.Lat}
                    closeOnClick={false}
                    onClose={() => this.props.dispatch(clickPin(null))}
                    className={"popup"}
                >
                    <div className="pin-popup">
                        <div className="popup-name">{business.Name}</div>
                        <div className="popup-address">{business.Address}</div>
                        <Link to={"/business/" + business.id}>
                            <div className="popup-more-deals">
                                {business.Deals.length} Deals Available
                            </div>
                        </Link>
                    </div>
                </Popup>
            )
        );
    }

    // returns correct id if detailed page, else null
    getDetailBusinessId(props = this.props) {
        const { pathname } = props.location;
        return getBusinessIdFromPath(pathname);
    }

    getDetailBusiness(id) {
        if (id) {
            const detailBusiness = this.props.business.filter((business) => {
                // iirc it needs to be == instead of ===
                return business.id == id;
            });
            if (detailBusiness && detailBusiness.length > 0) {
                return detailBusiness[0];
            }
        }
        return null;
    }

    getLocation(detailBusiness) {
        if (detailBusiness) {
            return { lat: detailBusiness.Lat, lon: detailBusiness.Lon };
        }
        return this.props.currentLocation;
    }

    getMarkerSource(detailBusiness) {
        const featureCollection = {
            type: "FeatureCollection",
            features: [],
        };

        let business;
        // filter to just one business for detailed page
        if (detailBusiness) {
            business = [detailBusiness];
        } else {
            const businesses = filterBusiness(
                this.props.business,
                this.props.filters,
                this.props.currentLocation
            );
            business = businesses[0].concat(businesses[1]);
        }

        business.map((business) => {
            // enlarge icon when conditions meet
            // this will be fed to properties and then used by layer
            const enlarge = !!(
                (this.props.clickedBusiness &&
                    business.id === this.props.clickedBusiness.id) ||
                (this.props.hoveredBusiness &&
                    business.id === this.props.hoveredBusiness.id) ||
                (detailBusiness && business.id === detailBusiness.id)
            );

            const feature = {
                id: "business-" + business.id,
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [business.Lon, business.Lat],
                },
                properties: {
                    business: business,
                    enlarge: enlarge,
                    outsideFilter: !!business.outsideFilter,
                },
            };

            featureCollection.features.push(feature);
        });

        return (
            <Source
                id="marker-source"
                type="geojson"
                data={featureCollection}
            />
        );
    }

    getMarkerLayer() {
        return (
            <Layer
                type="symbol"
                id="marker-layer"
                source="marker-source"
                layout={{
                    "icon-image": [
                        "case",
                        ["get", "outsideFilter"],
                        "marker-image-grey",
                        "marker-image",
                    ],
                    "icon-allow-overlap": true,
                    "icon-anchor": "bottom",
                    "icon-ignore-placement": true,
                    // if enlarge property set, make the icon bigger
                    "icon-size": ["case", ["get", "enlarge"], 1.4, 1],
                }}
            />
        );
    }

    mapClick = (e) => {
        if (!e.features || e.features.length === 0) {
            return;
        }
        // TODO: Move to business id and maintain map from id -> business somewhere instead of list for easier lookup
        let business = e.features[0].properties.business;
        if (!business) {
            return;
        }
        this.onPinClick(JSON.parse(business));
    };

    mapHover = (e) => {
        if (!e.features || e.features.length === 0) {
            return;
        }
        // TODO: Move to business id and maintain map from id -> business somewhere instead of list for easier lookup
        let business = e.features[0].properties.business;
        if (!business) {
            return;
        }
        this.onPinHover(JSON.parse(business));
    };

    render = () => {
        const id = this.getDetailBusinessId();
        const detailBusiness = this.getDetailBusiness(id);

        if (
            this.props.fetchBusiness !== "loaded" ||
            this.props.currentLocation.lat === -1
        ) {
            return <div />;
        }

        if (id && !detailBusiness) {
            return (
                <div className="no-deals-oops-img">
                    <div>
                        <img src={noDealsImg} />
                    </div>
                </div>
            );
        }

        const location = this.getLocation(detailBusiness);

        let mapGL = (
            <ReactMapGL
                mapboxApiAccessToken="pk.eyJ1IjoiYW51cmFna3lhbCIsImEiOiJja2JwcmJsbWQwcGoyMndsbTA0bW44ODR5In0.6UMvRvv9Sor7HTsCaYkyvg"
                width="100%"
                height="100%"
                ref={(map) => (this.mapRef = map)}
                latitude={location.lat}
                longitude={location.lon}
                {...this.state.viewport}
                onViewportChange={(viewport) => {
                    this.onViewportChange(viewport);
                }}
                onNativeClick={this.mapClick}
                interactiveLayerIds={["marker-layer"]}
                onLoad={() => {
                    if (this.mapRef) {
                        const markerImg = new Image(18, 26);
                        markerImg.onload = () => {
                            if (!this.mapRef) {
                                return;
                            }
                            this.mapRef
                                .getMap()
                                .addImage("marker-image", markerImg);
                        };
                        markerImg.src = markerIcon;

                        const markerImgGrey = new Image(18, 26);
                        markerImgGrey.onload = () => {
                            if (!this.mapRef) {
                                return;
                            }
                            this.mapRef
                                .getMap()
                                .addImage("marker-image-grey", markerImgGrey);
                        };
                        markerImgGrey.src = markerIconGrey;
                    }
                }}
                //onHover={this.mapHover}
            >
                {/*{this.getPin(detailBusiness)}*/}

                {this._renderPopup()}

                {this.getMarkerSource(detailBusiness)}

                {this.getMarkerLayer()}

                <GeolocateControl
                    style={geolocateStyle}
                    // detail page shouldn't geolocate
                    auto={this.state.autoGeolocate}
                    positionOptions={{
                        enableHighAccuracy: false,
                        timeout: 5000,
                    }}
                    onViewportChange={(viewport) => {
                        viewport.zoom = Math.min(
                            viewport.zoom,
                            isMobile ? defaultZoomMobile : defaultZoomDesktop
                        );
                        this.onViewportChange(viewport);
                    }}
                    onGeolocate={(e) => {
                        this.props.dispatch(
                            changeLocation(
                                e.coords.latitude,
                                e.coords.longitude
                            )
                        );
                    }}
                />

                <div style={navStyle}>
                    <NavigationControl showCompass={false} />
                </div>

                <div style={fullscreenStyle}>
                    <FullscreenControl />
                </div>
            </ReactMapGL>
        );
        return mapGL;
    };
}

export default Map;
