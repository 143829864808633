import * as React from "react";
import "../css/business-detail.css";
import connect from "react-redux/lib/connect/connect";
import { calculateDistance } from "../utils/geoDistanceUtils";
import {
    featurePills,
    formatDaysOfWeekDeals,
    priceIndicator,
} from "../utils/businessUtil";
import { Link } from "react-router-dom";
import ImagesCarousel from "./imagesCarousel";
import { scrollToTop } from "../utils/commonUtils";
import DealsInTabs from "./dealsInTabs";
import { dealsByDayOfWeek } from "../utils/dealUtils";
import FullScreenImages from "./fullScreenImages";
import ReactGA from "react-ga";
import { actions, detailPageEvent } from "../metrics/detailedPage";
import { getSeoDetailPage } from "../utils/seoUtils";

const mapStateToProps = (state) => {
    return {
        business: state.business,
        location: state.location,
        dayFilter: state.filters.day,
        fetchBusiness: state.fetchBusiness,
    };
};

class BusinessDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showFullImages: false,
            imgIndex: 0,
        };
    }
    componentDidMount() {
        scrollToTop();
    }

    deals(business) {
        const deals = business.Deals;

        if (!deals) {
            return;
        }

        const byDayOfWeek = dealsByDayOfWeek(deals, business.TimeSlots);
        return formatDaysOfWeekDeals(byDayOfWeek, true);
    }

    shareExternal() {
        navigator
            .share({
                title: "Checkout these amazing deals!",
                url: document.location.href,
                text: "Checkout these amazing deals!",
            })
            .then(() => {
                console.log("shared successfully.");
            })
            .catch(console.error);
    }

    getFilteredPhotos(photos) {
        let filtered = photos.filter((photo) => !photo.ForPhone);
        if (filtered.length > 5) {
            return filtered.slice(0, 5);
        }
        return filtered;
    }

    render() {
        if (this.props.fetchBusiness !== "loaded") {
            return <div className="column business-detail-col" />;
        }

        const { businessPath } = this.props.match.params;
        const id = businessPath.split("-")[0];

        ReactGA.pageview("/business/" + businessPath);

        const businessForId = this.props.business.filter((business) => {
            return business.id == id;
        });

        let business = businessForId[0];

        if (!business) {
            return (
                <div className="column business-detail-col">
                    <div className="no-deals-oops">
                        Oops.. Looks like this business currently has no deals
                        available. If you think this is in error, please let us
                        know.
                    </div>
                </div>
            );
        }

        let distance = -1;
        if (
            this.props.location.lat &&
            this.props.location.lon &&
            this.props.location.lat !== -1
        ) {
            distance = calculateDistance(
                this.props.location.lat,
                this.props.location.lon,
                business.Lat,
                business.Lon
            );
        }

        return (
            <div className="column business-detail-col">
                {getSeoDetailPage(business)}
                <div className="biz-detail">
                    <div className="business-detail-top">
                        <div className="left">
                            <p className="biz-detail-title">
                                <h1 className="biz-detail-name">
                                    {business.Name}
                                </h1>
                                {distance > 0 && (
                                    <span className="biz-detail-distance">
                                        <span className="distance-pill">
                                            {distance} km
                                        </span>
                                    </span>
                                )}
                            </p>

                            <p className="business-detail-props">
                                <div className="business-detail-rating-props">
                                    <span className="fas fa-star" />
                                    {business.Rating && (
                                        <span className="rating">
                                            {business.Rating.toFixed(1)}
                                        </span>
                                    )}
                                    {business.RatingCount && (
                                        <span className="rating-count">
                                            &nbsp;({business.RatingCount})
                                        </span>
                                    )}
                                    {business.PriceLevel && (
                                        <span className="price-indicator">
                                            &nbsp;&bull;&nbsp;
                                            <span className="">
                                                {priceIndicator(
                                                    business.PriceLevel
                                                )}
                                            </span>
                                        </span>
                                    )}
                                </div>
                                <span className="biz-detail-features">
                                    {featurePills(business.Features)}
                                </span>
                            </p>
                        </div>
                        <div className="right">
                            <Link
                                className="delete"
                                to="/"
                                onClick={() =>
                                    detailPageEvent(actions.clickCancel, id)
                                }
                            />
                        </div>
                    </div>
                    <p className="biz-detail-address">
                        <span className="fas fa-address-card" />
                        <a
                            href={
                                "https://www.google.com/maps/search/?api=1&query=" +
                                encodeURI(business.Address)
                            }
                            target="_blank"
                            onClick={() => {
                                detailPageEvent(actions.clickAddress, id);
                            }}
                        >
                            {business.Address}
                        </a>
                    </p>
                    <p className="biz-detail-phone">
                        <span className="fas fa-phone" />
                        <a
                            href={"tel:" + business.Phone}
                            onClick={() =>
                                detailPageEvent(actions.clickPhone, id)
                            }
                        >
                            {business.Phone}
                        </a>
                    </p>
                    <div className="biz-detail-links">
                        {business.WebsiteUrl && (
                            <a
                                className="biz-detail-link"
                                href={business.WebsiteUrl}
                                target="_blank"
                                onClick={() => {
                                    if (
                                        business.MenuUrl !== business.WebsiteUrl
                                    ) {
                                        detailPageEvent(
                                            actions.clickWebsite,
                                            id
                                        );
                                    } else {
                                        detailPageEvent(
                                            actions.clickWebsiteMenu,
                                            id
                                        );
                                    }
                                }}
                            >
                                {business.MenuUrl !== business.WebsiteUrl
                                    ? "Website"
                                    : "Website / Menu"}
                            </a>
                        )}

                        {business.MenuUrl &&
                            business.MenuUrl !== business.WebsiteUrl && (
                                <a
                                    className="biz-detail-link"
                                    href={business.MenuUrl}
                                    target="_blank"
                                    onClick={() =>
                                        detailPageEvent(actions.clickMenu, id)
                                    }
                                >
                                    Menu
                                </a>
                            )}
                    </div>

                    <DealsInTabs
                        deals={this.deals(business)}
                        dayFilter={this.props.dayFilter}
                    />

                    <div className="external-link">
                        <span className="fas fa-envelope-open-text" />
                        <a
                            href={
                                "mailto:hello@dealiem.com?subject=Suggest an edit for " +
                                business.Name
                            }
                            target="_blank"
                            className="external-link-text"
                            onClick={() =>
                                detailPageEvent(actions.clickSuggestEdit, id)
                            }
                        >
                            Suggest an edit
                        </a>

                        <span className="fas fa-share-alt-square" />
                        {(navigator.share && (
                            <a
                                className="external-link-text"
                                onClick={() => {
                                    this.shareExternal();
                                    detailPageEvent(actions.clickShareApp, id);
                                }}
                            >
                                Share this deal
                            </a>
                        )) || (
                            <a
                                href={
                                    "mailto:?subject=Amazing deals at " +
                                    business.Name +
                                    "&body=" +
                                    document.location.href
                                }
                                target="_blank"
                                className="external-link-text"
                                onClick={() =>
                                    detailPageEvent(actions.clickShareEmail, id)
                                }
                            >
                                Share this deal
                            </a>
                        )}
                    </div>

                    {/*Image Carousel*/}
                    <div className="img-carousel">
                        <ImagesCarousel
                            businessName={business.Name}
                            images={this.getFilteredPhotos(business.Photos)}
                            onImgClick={(imgIndex) => {
                                this.setState({
                                    showFullImages: true,
                                    imgIndex: imgIndex,
                                });
                                detailPageEvent(actions.clickImage, id);
                            }}
                        />
                    </div>

                    {business.Description && business.Description != "" ? (
                        <div className="biz-description">
                            <i>
                                About {business.Name}: {business.Description}
                            </i>
                        </div>
                    ) : (
                        <div />
                    )}

                    {/*Image Full Screen*/}
                    {this.state.showFullImages && (
                        <FullScreenImages
                            imgIndex={this.state.imgIndex}
                            images={this.getFilteredPhotos(business.Photos).map(
                                (photo) => photo.Url
                            )}
                            onCloseClick={() =>
                                this.setState({ showFullImages: false })
                            }
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(BusinessDetail);
