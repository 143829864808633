import { combineReducers } from "redux";
import {
    CHANGE_LOCATION,
    INIT_BUSINESS,
    ADD_BUSINESS,
    REQUEST_BUSINESS,
    INIT_MAP,
    DAY_FILTER,
    TIME_FILTER,
    RATING_FILTER,
    NEIGHBORHOOD_FILTER,
    MARKET_FILTER,
    CLEAR_FILTERS,
    CHANGE_BOUNDS,
    CATEGORY_FILTER,
    LOADED_BUSINESS,
    CLICK_PIN,
    SEARCH_FILTER,
    SLIDING_MENU_TOGGLE,
    HOVER_PIN,
    SAVE_SCROLL_POSITION,
    RESET_MAP,
    TOGGLE_MODAL,
    CLOSE_NOTIFICATION,
    CHANGE_MARKET,
} from "./actionTypes";
import { replaceImageUrlS3ToCF } from "../utils/businessUtil";

const cloneDeep = require("clone-deep");
const queryString = require("query-string");

function mapInitialized(state = false, action) {
    switch (action.type) {
        case INIT_MAP:
            return true;
        case RESET_MAP:
            return false;
    }
    return state;
}

function location(state = { market: "", lat: -1, lon: -1 }, action) {
    switch (action.type) {
        case CHANGE_LOCATION:
            return {
                lat: action.lat,
                lon: action.lon,
            };
        case CHANGE_MARKET:
            switch (action.market) {
                case "gta":
                    return {
                        market: "gta",
                        lat: 43.6532,
                        lon: -79.3832,
                    };
                case "austin":
                    return {
                        market: "austin",
                        lat: 30.2672,
                        lon: -97.7431,
                    };
            }
            break;
        case CHANGE_BOUNDS:
            return Object.assign({}, state, { sw: action.sw, ne: action.ne });
    }
    return state;
}

function fetchBusiness(state = "", action) {
    switch (action.type) {
        case REQUEST_BUSINESS:
            return "requested";
        case LOADED_BUSINESS:
            return "loaded";
    }

    return state;
}

function business(state = [], action) {
    switch (action.type) {
        case INIT_BUSINESS:
            return action.business;
        case ADD_BUSINESS:
            let businessWithId = cloneDeep(action.business).map((business) => {
                business.id = business.Id;
                replaceImageUrlS3ToCF(business);
                return business;
            });
            return Object.assign([], ...state, businessWithId);
    }
    return state;
}

function isFetching(state = false, action) {
    switch (action.type) {
        case REQUEST_BUSINESS:
            return true;
        default:
            return false;
    }
}

function filters(
    state = {
        search: queryString.parse(document.location.search)["q"],
        day: (new Date().getDay() + 1).toString(),
        time: "",
        rating: "",
        neighborhood: "",
        category: "",
        market: "gta",
    },
    action
) {
    switch (action.type) {
        case SEARCH_FILTER:
            return Object.assign({}, state, { search: action.search });
        case DAY_FILTER:
            return Object.assign({}, state, { day: action.day });
        case TIME_FILTER:
            return Object.assign({}, state, { time: action.time });
        case RATING_FILTER:
            return Object.assign({}, state, { rating: action.rating });
        case NEIGHBORHOOD_FILTER:
            return Object.assign({}, state, {
                neighborhood: action.neighborhood,
            });
        case CATEGORY_FILTER:
            return Object.assign({}, state, { category: action.category });
        case MARKET_FILTER:
            return Object.assign({}, state, { market: action.market });
        case CLEAR_FILTERS:
            return {
                day: "",
                time: "",
                rating: "",
                neighborhood: "",
                category: "",
                search: "",
            };
    }
    return state;
}

function pin(state = { clickedBusiness: "", hoveredBusiness: "" }, action) {
    switch (action.type) {
        case CLICK_PIN:
            if (action.business != null) {
                return Object.assign({}, state, {
                    clickedBusiness: action.business,
                    hoveredBusiness: null,
                });
            }
            return Object.assign({}, state, { clickedBusiness: null });
        case HOVER_PIN:
            if (!state.clickedBusiness) {
                return Object.assign({}, state, {
                    hoveredBusiness: action.business,
                });
            }
    }
    return state;
}

function slidingMenu(state = { isOpen: false }, action) {
    switch (action.type) {
        case SLIDING_MENU_TOGGLE:
            return Object.assign({}, state, { isOpen: action.isOpen });
    }
    return state;
}

function scroll(state = { documentPosition: 0, listPosition: 0 }, action) {
    switch (action.type) {
        case SAVE_SCROLL_POSITION:
            return {
                documentPosition: action.documentPosition,
                listPosition: action.listPosition,
            };
    }
    return state;
}

function messages(
    state = { openModal: false, showNotification: true },
    action
) {
    switch (action.type) {
        case TOGGLE_MODAL:
            return Object.assign({}, state, { openModal: action.isOpen });
        case CLOSE_NOTIFICATION:
            return Object.assign({}, state, { showNotification: false });
    }
    return state;
}

const reducers = combineReducers({
    mapInitialized,
    location,
    business,
    isFetching,
    filters,
    fetchBusiness,
    pin,
    slidingMenu,
    scroll,
    messages,
});
export default reducers;
