import React from "react";
import Tabs from "react-responsive-tabs";
// IMPORTANT you need to include the default styles
import "react-responsive-tabs/styles.css";
import "../css/deals-in-tabs.css";

const days = {
    1: "Sun",
    2: "Mon",
    3: "Tue",
    4: "Wed",
    5: "Thurs",
    6: "Fri",
    7: "Sat",
};

class DealsInTabs extends React.Component {
    getTabs() {
        return Object.keys(days).map((key) => ({
            title: days[key],
            content: this.props.deals[key],
            key: key,
            tabClassName:
                Object.keys(this.props.deals).indexOf(key) >= 0
                    ? "tabbed-deals-tab"
                    : "tabbed-deals-tab disabled",
            panelClassName: "tabbed-deals-panel",
        }));
    }

    render() {
        // for some reason some deals have the day as 0 which leads to
        // default tab being no-op.
        // TODO: Investigate why deals have day as 0. Ideally they should be 1-7
        let defaultKey =
            Object.keys(this.props.deals)[0] === "0"
                ? Object.keys(this.props.deals)[1]
                : Object.keys(this.props.deals)[0];

        if (this.props.dayFilter) {
            // if day filter is explicitly set, try to go to that tab if there
            // is any deals for that day else it will fallback to the first
            // day any deal is available
            if (
                Object.keys(this.props.deals).indexOf(this.props.dayFilter) >= 0
            ) {
                defaultKey = this.props.dayFilter;
            }
        } else {
            // if anyday is set, try setting it to today if there is any deals
            // for today else it will fallback to the first day any deal is
            // available
            const today = (new Date().getDay() + 1).toString();
            if (Object.keys(this.props.deals).indexOf(today) >= 0) {
                defaultKey = today;
            }
        }

        return (
            <Tabs
                items={this.getTabs(this.props.deals)}
                showMore={false}
                transform={false}
                tabsWrapperClass="tabbed-deals-container"
                selectedTabKey={defaultKey}
                showInkBar={true}
            />
        );
    }
}

export default DealsInTabs;
