import ReactDOM from "react-dom";
import Main from "./components/main";
import * as serviceWorker from "./serviceWorker";
import React from "react";
import { compatibleBrowser } from "./utils/commonUtils";

function noop() {}

if (process.env.NODE_ENV !== "development") {
    console.log = noop;
    console.warn = noop;
    console.error = noop;
}

if (compatibleBrowser()) {
    ReactDOM.render(<Main />, document.getElementById("root"));
} else {
    document
        .getElementById("root")
        .setAttribute("style", "text-align:center;margin-top:50px;");
    document.getElementById("root").innerHTML =
        "Browser not supported. Dealiem works best with Chrome or Safari.";
}
serviceWorker.unregister();
