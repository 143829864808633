// location
export const INIT_MAP = "init_map";
export const RESET_MAP = "reset_map";
export const CHANGE_LOCATION = "CHANGE_LOCATION";
export const CHANGE_BOUNDS = "CHANGE_BOUNDS";
export const CHANGE_MARKET = "CHANGE_MARKET";

// deals
export const INIT_BUSINESS = "init_business";
export const ADD_BUSINESS = "add_business";

export const REQUEST_BUSINESS = "REQUEST_BUSINESS";
export const LOADED_BUSINESS = "LOADED_BUSINESS";

// filters
export const DAY_FILTER = "DAY_FILTER";
export const TIME_FILTER = "TIME_FILTER";
export const RATING_FILTER = "RATING_FILTER";
export const NEIGHBORHOOD_FILTER = "NEIGHBORHOOD_FILTER";
export const CATEGORY_FILTER = "CATEGORY_FILTER";
export const SEARCH_FILTER = "SEARCH_FILTER";
export const MARKET_FILTER = "MARKET_FILTER";
export const CLEAR_FILTERS = "CLEAR_FILTERS";

// pin highlight
export const CLICK_PIN = "CLICK_PIN";
export const HOVER_PIN = "HOVER_PIN";

// sliding menu toggle
export const SLIDING_MENU_TOGGLE = "SLIDING_MENU_TOGGLE";

// scroll position
export const SAVE_SCROLL_POSITION = "SAVE_SCROLL_POSITION";

// messages
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
