import React from "react";
import { equalTimeSlots } from "../utils/timeSlotUtils";
import {
    featurePills,
    formatDeals,
    priceIndicator,
} from "../utils/businessUtil";
import { Link } from "react-router-dom";
import { actions, homePageEvent } from "../metrics/detailedPage";
import { toKebabCase } from "../utils/commonUtils";

class BusinessCard extends React.Component {
    deals() {
        let dealsByTimeSlot = new Map();

        let i = 0;

        this.props.deals.forEach((deal) => {
            if (i === 3) {
                return;
            }
            let matchingTimeSlot = false;
            dealsByTimeSlot.forEach((deals, timeSlots) => {
                if (equalTimeSlots(timeSlots, deal.TimeSlots)) {
                    deals.push(deal);
                    matchingTimeSlot = true;
                }
            });
            if (!matchingTimeSlot) {
                dealsByTimeSlot.set(deal.TimeSlots, [deal]);
            }
            i++;
        });

        return formatDeals(dealsByTimeSlot, false);
    }

    render() {
        let remainingDeals = Math.max(0, this.props.deals.length - 3);

        return (
            <Link
                to={
                    "/business/" +
                    this.props.id +
                    "-" +
                    toKebabCase(this.props.name)
                }
                onClick={() => homePageEvent(actions.clickCard, this.props.id)}
            >
                <div
                    className={
                        (this.props.isClicked
                            ? "deal-item highlight "
                            : "deal-item ") +
                        (this.props.outsideFilter ? "outside-filter" : "")
                    }
                    id={this.props.id}
                    onMouseEnter={this.props.onMouseEnter}
                    onMouseLeave={this.props.onMouseLeave}
                >
                    <div className="deal-top">
                        <h1 className="biz-name">{this.props.name}</h1>
                        {this.props.distance > 0 && (
                            <span className="biz-distance">
                                <span className="distance-pill">
                                    {this.props.distance} km
                                </span>
                            </span>
                        )}
                    </div>
                    <div className="deal-ver-middle">
                        <span className="business-props">
                            <span className="fas fa-star" />
                            {this.props.rating && (
                                <span className="rating">
                                    {this.props.rating}
                                </span>
                            )}
                            {this.props.ratingCount && (
                                <span className="rating-count">
                                    &nbsp;({this.props.ratingCount})
                                </span>
                            )}
                            {this.props.priceLevel && (
                                <span className="price-indicator">
                                    &nbsp;&bull;&nbsp;
                                    <span className="">
                                        {priceIndicator(this.props.priceLevel)}
                                    </span>
                                </span>
                            )}
                        </span>
                        <span className="features">
                            {featurePills(this.props.features)}
                        </span>
                    </div>
                    <div className="deal-bottom">
                        <div className="deal-left">
                            <figure className="image">
                                <img
                                    className="deal-thumbnail"
                                    src={this.props.img}
                                    alt={this.props.name}
                                />
                            </figure>
                        </div>
                        <div className="deal-middle">
                            <div className="deals">{this.deals()}</div>
                            {remainingDeals > 0 && (
                                <div className="more-deals has-text-right">
                                    ... {remainingDeals} more deal
                                    {remainingDeals > 1 && "s"}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

export default BusinessCard;
