import React from "react";
import {
    changeLocation,
    clearFilters,
    updateMarket,
    updateCategoryFilter,
    updateDayFilter,
    updateNeighborhood,
    updateRatingFilter,
    updateSearchFilter,
    updateTimeFilter,
} from "../store/actionCreator";
import connect from "react-redux/lib/connect/connect";
import { withRouter } from "react-router-dom";
import * as stemmer from "stemmer";
import "../css/select-button.css";
import "../css/filter-section.css";
import _ from "lodash";
import { actions, homePageEvent } from "../metrics/detailedPage";

const mapStateToProps = (state) => {
    return {
        dayFilter: state.filters.day,
        timeFilter: state.filters.time,
        ratingFilter: state.filters.rating,
        neighborhoodFilter: state.filters.neighborhood,
        categoryFilter: state.filters.category,
        searchFilter: state.filters.search,
        marketFilter: state.filters.market,
    };
};

class FilterSection extends React.Component {
    changeDayFilter = (event) => {
        this.props.dispatch(updateDayFilter(event.target.value));
        this.props.history.push("/");
    };

    changeTimeFilter = (event) => {
        this.props.dispatch(updateTimeFilter(event.target.value));
        this.props.history.push("/");
    };

    changeRatingFilter = (event) => {
        this.props.dispatch(updateRatingFilter(event.target.value));
        this.props.history.push("/");
    };

    changeNeighborhoodFilter = (event) => {
        this.props.dispatch(updateNeighborhood(event.target.value));
        this.props.history.push("/");
    };

    changeCategoriesFilter = (event) => {
        this.props.dispatch(updateCategoryFilter(event.target.value));
        this.props.history.push("/");
    };

    changeSearchFilter = (event) => {
        const lower = event.target.value.toLowerCase();
        const stemmed = stemmer(lower);
        const combined = lower + "||" + stemmed;
        _.debounce(() => {
            this.props.dispatch(updateSearchFilter(combined));
        }, 300)();
        this.props.history.push("/");
    };

    changeMarketFilter = (event) => {
        const lower = event.target.value.toLowerCase();
        this.props.dispatch(updateMarket(lower));
        this.props.history.push("/");
    };

    clearFilters = (event) => {
        this.props.dispatch(clearFilters());
        this.props.history.push("/");
    };

    render() {
        return (
            <section className="filter-section">
                <div className="container">
                    <div className="columns">
                        <div className="column  deal-loc-col">
                            {/*<p className="heading">Search for deals</p>*/}

                            <select
                                className="select-css filter-item market-filter"
                                onChange={this.changeMarketFilter}
                                value={this.props.marketFilter}
                            >
                                <option value="gta">Ontario (CA)</option>
                                <option value="austin">Austin</option>
                            </select>

                            <select
                                className="select-css filter-item day-filter"
                                onChange={this.changeDayFilter}
                                onClick={() => {
                                    homePageEvent(actions.updateDay, "");
                                }}
                                value={this.props.dayFilter}
                            >
                                <option value="">Any Day</option>
                                <option value="1">Sunday</option>
                                <option value="2">Monday</option>
                                <option value="3">Tuesday</option>
                                <option value="4">Wednesday</option>
                                <option value="5">Thursday</option>
                                <option value="6">Friday</option>
                                <option value="7">Saturday</option>
                            </select>

                            <select
                                className="select-css filter-item time-filter"
                                onChange={this.changeTimeFilter}
                                onClick={() => {
                                    homePageEvent(actions.updateTime, "");
                                }}
                                value={this.props.timeFilter}
                            >
                                <option value="">Any Time</option>
                                <option value="0800">8 am</option>
                                <option value="0900">9 am</option>
                                <option value="1000">10 am</option>
                                <option value="1100">11 am</option>
                                <option value="1200">12 pm</option>
                                <option value="1300">1 pm</option>
                                <option value="1400">2 pm</option>
                                <option value="1500">3 pm</option>
                                <option value="1600">4 pm</option>
                                <option value="1700">5 pm</option>
                                <option value="1800">6 pm</option>
                                <option value="1900">7 pm</option>
                                <option value="2000">8 pm</option>
                                <option value="2100">9 pm</option>
                                <option value="2200">10 pm</option>
                                <option value="2300">11 pm</option>
                                <option value="2400">12 am</option>
                                <option value="2500">1 am</option>
                            </select>

                            <select
                                className="select-css filter-item category-filter"
                                onChange={this.changeCategoriesFilter}
                                onClick={() => {
                                    homePageEvent(actions.updateCategory, "");
                                }}
                                value={this.props.categoryFilter}
                            >
                                <option value="">All Categories</option>
                                <option value="1">Food</option>
                                <option value="2">Drinks</option>
                                <option value="3">Things to do</option>
                            </select>

                            <input
                                className="search-box filter-item"
                                placeholder="find: burger, beer, business etc.."
                                onChange={this.changeSearchFilter}
                                onClick={() => {
                                    homePageEvent(actions.textSearch, "");
                                }}
                            />

                            {/*<div className="filter-item reset-button" onClick={this.clearFilters}>*/}
                            {/*    <span className="clear-text">clear</span>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withRouter(connect(mapStateToProps)(FilterSection));
