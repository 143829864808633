import * as React from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

class FullScreenImages extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: this.props.imgIndex,
        };
    }

    render() {
        const images = this.props.images;
        const photoIndex = this.state.photoIndex;
        return (
            <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={
                    images[(photoIndex + images.length - 1) % images.length]
                }
                onCloseRequest={this.props.onCloseClick}
                onMovePrevRequest={() =>
                    this.setState({
                        photoIndex:
                            (photoIndex + images.length - 1) % images.length,
                    })
                }
                onMoveNextRequest={() =>
                    this.setState({
                        photoIndex: (photoIndex + 1) % images.length,
                    })
                }
            />
        );
    }
}

export default FullScreenImages;
