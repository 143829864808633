import ReactGA from "react-ga";

const categories = {
    homePage: "HOME_PAGE",
    detailPage: "DETAIL_PAGE",
};

export const actions = {
    // nav bar
    clickAbout: "CLICK_ABOUT",
    clickForBusines: "CLICK_FOR_BUSINESS",
    clickBlog: "CLICK_BLOG",

    // filter section
    updateDay: "UPDATE_FILTER_DAY",
    updateTime: "UPDATE_FILTER_TIME",
    updateCategory: "UPDATE_FILTER_CATEGORY",
    textSearch: "TEXT_SEARCH",

    // home page
    clickCard: "CLICK_CARD",

    // detailed page
    clickAddress: "CLICK_ADDRESS",
    clickPhone: "CLICK_PHONE",
    clickWebsite: "CLICK_WEBSITE",
    clickMenu: "CLICK_MENU",
    clickWebsiteMenu: "CLICK_WEBSITE_MENU",
    clickCancel: "CLICK_CANCEL",
    clickSuggestEdit: "CLICK_SUGGEST_EDIT",
    clickShareApp: "CLICK_SHARE_APP",
    clickShareEmail: "CLICK_SHARE_EMAIL",
    clickImage: "CLICK_IMAGE",

    // footer
    clickTerms: "CLICK_TERMS",
    clickPrivacy: "CLICK_PRIVACY",
    clickMail: "CLICK_MAIL",
    clickFb: "CLICK_FB",
    clickInsta: "CLICK_INSTA",
    clickLinkedIn: "CLICK_LINKEDIN",
};

export function homePageEvent(action, id) {
    ReactGA.event({
        category: categories.homePage,
        action: action,
        label: id,
    });
}

export function detailPageEvent(action, id) {
    ReactGA.event({
        category: categories.detailPage,
        action: action,
        label: id,
    });
}
