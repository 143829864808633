import favicon from "../images/dealiem_favicon.ico";
import Helmet from "react-helmet";
import * as React from "react";

const queryString = require("query-string");

export function getSeoMainPage() {
    const seoFields = {
        title: "Best happy hour and deals finder in Toronto - Dealiem",
        description:
            "Find your next happy hour, drink and food special, or daily deals, near you all in one place",
        keywords:
            "dealiem, deal, deals, offer, happy, discount, food, cheap, hour, toronto, restaurants, near, specials, wings, oyster, beer",
    };

    const query = queryString.parse(document.location.search)["q"];
    if (query && query !== "") {
        seoFields.title =
            capitalizeFirstLetter(query) +
            " deals in Toronto for any day of the week - Dealiem";
        seoFields.description =
            "Find your next " +
            query.toLowerCase() +
            ", happy hour, drink and food special, or daily deals, near you all in one place";
        seoFields.keywords += ", " + query.toLowerCase();
    }

    return (
        <Helmet
            onChangeClientState={(newState, addedTags, removedTags) => {
                document
                    .querySelectorAll("meta[original-meta-tag='true']")
                    .forEach(function (child) {
                        child.parentNode.removeChild(child);
                    });
            }}
        >
            <meta charSet="utf-8" />
            <title>{seoFields.title}</title>
            <link rel="icon" href={favicon} />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1"
            />
            <meta name="theme-color" content="#f9f9f9" />
            <meta name="description" content={seoFields.description} />
            <meta name="keywords" content={seoFields.keywords} />
        </Helmet>
    );
}

export function getSeoDetailPage(business) {
    const seoFields = {
        title: "Best happy hour and deals finder in Toronto - Dealiem",
        description:
            "Find your next happy hour, drink and food special, or daily deals, all in one place",
        keywords:
            "dealiem, deal, deals, offer, happy, discount, food, cheap, hour, toronto, restaurants, near, specials, wings, oyter, beer",
    };

    if (business.SeoTitle) {
        seoFields.title = business.SeoTitle;
    } else if (business.Name) {
        seoFields.title = business.Name + ": Best deals on Dealiem";
    }
    if (business.SeoDescription) {
        seoFields.description = business.SeoDescription;
    }
    if (business.SeoKeywords) {
        seoFields.keywords = business.SeoKeywords;
    }

    return (
        <Helmet
            onChangeClientState={(newState, addedTags, removedTags) => {
                document
                    .querySelectorAll("meta[original-meta-tag='true']")
                    .forEach(function (child) {
                        child.parentNode.removeChild(child);
                    });
            }}
        >
            <title>{seoFields.title}</title>
            <meta name="description" content={seoFields.description} />
            <meta name="keywords" content={seoFields.keywords} />
        </Helmet>
    );
}

function capitalizeFirstLetter(s) {
    s = s.toLowerCase();
    return s.charAt(0).toUpperCase() + s.slice(1);
}
