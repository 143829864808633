import React from "react";
import privacy from "../documents/dealiem_privacy_policy.pdf";
import terms from "../documents/dealiem_terms_of_use.pdf";
import "../css/footer.css";

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer">
                <div className="footer-top">
                    <div className="footer-about center-text">
                        <p>
                            <div className="footer-mission-heading">
                                About Us:
                            </div>
                            Dealiem is your ultimate guide to food & drink
                            specials, happy hour deals, & free things to do in{" "}
                            <a
                                className="underline-text"
                                href="https://dealiem.com/?q=&disableGeo=true"
                            >
                                Toronto
                            </a>{" "}
                            & beyond. Explore top restaurants, bars, pubs, art
                            galleries, & museums today.
                        </p>
                    </div>
                    <div className="footer-links center-text">
                        <p>
                            <a href="/about" target="_blank">
                                Subscribe
                            </a>
                        </p>
                        <p>
                            <a href="/about/business.html" target="_blank">
                                For Business
                            </a>
                        </p>
                        <p>
                            <a href="/blog" target="_blank">
                                Our Blog
                            </a>
                        </p>
                        <p className="social-icons">
                            <a href="mailto:hello@dealiem.com" target="_blank">
                                <span className="fas fa-envelope" />
                            </a>
                            <a
                                href="https://www.facebook.com/thedealiem"
                                target="_blank"
                            >
                                <span className="fab fa-facebook" />
                            </a>
                            <a
                                href="https://www.instagram.com/thedealiem/"
                                target="_blank"
                            >
                                <span className="fab fa-instagram" />
                            </a>
                            <a
                                href="https://www.linkedin.com/company/dealiem/"
                                target="_blank"
                            >
                                <span className="fab fa-linkedin" />
                            </a>
                        </p>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div>
                        <a href={privacy} target="_blank">
                            Privacy Policy
                        </a>
                    </div>
                    <div>
                        <a href={terms} target="_blank">
                            Terms of Use
                        </a>
                    </div>
                    <div>Copyright © Dealiem Inc. 2023</div>
                </div>
            </footer>
        );
    }
}

export default Footer;
