// path should look like /business/{id}
// else returns null
export function getBusinessIdFromPath(path) {
    const match = path.match(/\/business\/(?<id>[0-9]+)/);
    if (match && match.groups) {
        return match.groups["id"];
    }
    return null;
}

export function scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

// Not supporting MSIE at the moment
export function compatibleBrowser() {
    const ua = window.navigator.userAgent;
    return ua.indexOf("MSIE") < 0 && ua.indexOf("Trident") < 0;
}

export function toKebabCase(str) {
    return (
        str &&
        str
            .match(
                /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
            )
            .map((x) => x.toLowerCase())
            .join("-")
    );
}
