import React, { Component } from "react";
import CurrentLocationMap from "./currentLocationMap";
import BusinessToShow from "./businessToShow";
import connect from "react-redux/lib/connect/connect";
import BusinessDetail from "./businessDetail";
import { Route } from "react-router-dom";
import "../css/map.css";

const mapStateToProps = (state) => {
    return {
        mapInitialized: state.mapInitialized,
    };
};

class MainContainerComponent extends Component {
    render = () =>
        this.props.mapInitialized ? (
            <div className="columns">
                <div className="column map-col">
                    <div className="map">
                        <CurrentLocationMap />
                    </div>
                </div>
                <Route exact path="/" component={BusinessToShow} />
                <Route
                    exact
                    path="/business/:businessPath"
                    component={BusinessDetail}
                />
            </div>
        ) : (
            <div className="columns">
                <div className="column loading-col">
                    <div className="fa fa-refresh fa-spin" />
                    <div className="location-access">
                        Please allow location access if prompted
                    </div>
                </div>
            </div>
        );
}

const MainContainer = connect(mapStateToProps)(MainContainerComponent);
export default MainContainer;
