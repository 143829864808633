// returns a map with key day of week (1-7)
// the value would be another map. This map's key
// would be TimeSlot with only startTime and endTime
// and the value would be a list of deals
export function dealsByDayOfWeek(deals, businessTimeSlots) {
    const byDayMap = {};

    deals.forEach((deal) => {
        const timeSlots = deal.TimeSlots;
        timeSlots.forEach((timeSlot) => {
            const days = timeSlot.DaysOfWeek;
            days.forEach((day) => {
                if (!byDayMap[day]) {
                    byDayMap[day] = new Map();
                }
                const strippedTimeSlot = {
                    startTime: timeSlot.StartTime,
                    endTime: timeSlot.EndTime,
                };

                if (timeSlot.StartAsOpen && businessTimeSlots) {
                    businessTimeSlots.forEach((timeSlot) => {
                        if (timeSlot.DayOfWeek === day) {
                            strippedTimeSlot.startTime = timeSlot.StartTime;
                        }
                    });
                }

                if (timeSlot.EndAsClose && businessTimeSlots) {
                    businessTimeSlots.forEach((timeSlot) => {
                        if (timeSlot.DayOfWeek === day) {
                            strippedTimeSlot.endTime = timeSlot.EndTime;
                        }
                    });
                }

                // if timeslots are not valid at this point, skip it!
                if (
                    strippedTimeSlot.startTime === undefined ||
                    strippedTimeSlot.endTime === undefined
                ) {
                    return;
                }

                let found = false;
                for (let [timeSlotKey, dealsListValue] of byDayMap[
                    day
                ].entries()) {
                    if (
                        strippedTimeSlot.startTime == timeSlotKey.startTime &&
                        strippedTimeSlot.endTime == timeSlotKey.endTime
                    ) {
                        dealsListValue.push(deal);
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    byDayMap[day].set(strippedTimeSlot, [deal]);
                }
            });
        });
    });

    return byDayMap;
}
