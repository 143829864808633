import * as React from "react";
import NavBar from "./navBar";
import FilterSection from "./filterSection";
import MainSection from "./mainSection";
import Footer from "./footer";
import "../css/common.css";
import { getSeoMainPage } from "../utils/seoUtils";
import BannerNotification from "./bannerNotification";

class Home extends React.Component {
    render() {
        return (
            <div className="main">
                {getSeoMainPage()}
                <BannerNotification />
                <NavBar />
                <FilterSection />
                <MainSection />
                <Footer />
            </div>
        );
    }
}

export default Home;
