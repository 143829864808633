import React from "react";
import logo from "../images/dealiem-logo-combined.svg";
import SlidingMenu from "./slidingMenu";
import { slidingMenuToggle } from "../store/actionCreator";
import connect from "react-redux/lib/connect/connect";
import { Link } from "react-router-dom";

class NavBar extends React.Component {
    enableMenu = () => {
        this.props.dispatch(slidingMenuToggle(true));
    };

    render() {
        return (
            <nav className="navbar">
                <div className="container">
                    <div className="navbar-items">
                        <Link to="/">
                            <div className="nav-left">
                                <div className="navbar-item dealiem-logo">
                                    <img className="logo-img" src={logo} />
                                </div>
                                {/*<div className="navbar-item">*/}
                                {/*    Dealiem. Do more for less.*/}
                                {/*</div>*/}
                            </div>
                        </Link>
                        <div className="nav-right">
                            <div className="navbar-item">
                                <a href="/about" target="_blank">
                                    About
                                </a>
                            </div>
                            <div className="navbar-item">
                                <a href="/about/business.html" target="_blank">
                                    For Business
                                </a>
                            </div>
                            <div className="navbar-item">
                                <a href="/blog" target="_blank">
                                    Blog
                                </a>
                            </div>
                            <div
                                className="navbar-item sliding-menu-nav-btn"
                                onClick={() => this.enableMenu()}
                            >
                                <i className="fas fa-bars" />
                            </div>
                            <SlidingMenu />
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

export default connect()(NavBar);
