import React from "react";
import {
    compareTimeSlots,
    getFormattedStrippedTimeSlots,
    getFormattedTimeSlotsList,
} from "./daysUtil";
import { isChrome, isChromium } from "react-device-detect";

export function featurePills(features) {
    const featuresToDisplay = [];
    if (features) {
        features.forEach((feature) => {
            let featureStr = "";
            switch (feature) {
                case 1:
                    featureStr = "Sidewalk Patio";
                    break;
                case 2:
                    featureStr = "Courtyard Patio";
                    break;
                case 3:
                    featureStr = "Rooftop Patio";
                    break;
                case 4:
                    featureStr = "Backyard Patio";
                    break;
            }
            if (featureStr) {
                featuresToDisplay.push(
                    <span className="feature-pill">{featureStr}</span>
                );
            }
        });
    }
    return featuresToDisplay;
}

// dealsByTimeSlots is a map with the keys as a list of timeslots arrays, ie of the form [[0,1,2], [2,3,4], ...]
// and the value is a list of deals which were grouped to belong to the same timeslot group
// this function returns a formatted deals list with proper timeslot sorting and deals sorting by price
export function formatDeals(dealsByTimeSlot, priceBefore) {
    const sorted = new Map(
        [...dealsByTimeSlot].sort((a, b) => {
            return compareTimeSlots(a[0], b[0]);
        })
    );

    let first = true;
    const formattedDeals = [];
    sorted.forEach((deals, timeSlots) => {
        // also sorts each timeslot list
        let formattedTimeSlots = getFormattedTimeSlotsList(timeSlots);

        let timeSlotsToShow = [];
        formattedTimeSlots.forEach((timeSlot) => {
            let timeSlotToShow = (
                <div className="time-slot">
                    <div className="day">{timeSlot[0]}</div>
                    <div className="time">{timeSlot[1]}</div>
                </div>
            );
            timeSlotsToShow.push(timeSlotToShow);
        });

        let dealsToShow = [];
        deals
            .sort((deal1, deal2) => {
                if (
                    deal1.PriceAfter.startsWith("$") &&
                    deal2.PriceAfter.startsWith("$")
                ) {
                    const deal1Price = parseInt(deal1.PriceAfter.substr(1));
                    const deal2Price = parseInt(deal2.PriceAfter.substr(1));
                    return deal1Price - deal2Price;
                }
                if (!deal1.PriceAfter.startsWith("$")) {
                    return -1;
                }
                if (!deal2.PriceAfter.startsWith("$")) {
                    return 1;
                }
                return deal1.PriceAfter <= deal2.PriceAfter ? -1 : 1;
            })
            .forEach((deal) => {
                let dealToShow = (
                    <div className="deal-description">
                        <div className="price-after">{deal.PriceAfter}</div>
                        <div className="description">
                            {deal.Description}
                            {priceBefore && (
                                <span className="price-before">
                                    {deal.PriceBefore}
                                </span>
                            )}
                        </div>
                    </div>
                );
                dealsToShow.push(dealToShow);
            });

        formattedDeals.push(
            <div className={first ? "deals-details first" : "deals-details"}>
                <div className="left">
                    <p className="fas fa-clock timing-icon" />
                </div>
                <div className="right">
                    <div className="time-slots">{timeSlotsToShow}</div>
                    <div className="deal-descriptions">{dealsToShow}</div>
                </div>
            </div>
        );

        first = false;
    });

    return formattedDeals;
}

export function formatDaysOfWeekDeals(dealsByDayOfWeek, priceBefore) {
    const formattedDealsByWeek = {};

    Object.entries(dealsByDayOfWeek).forEach((entry) => {
        const dayOfWeek = entry[0];
        const dealsByTimeSlots = entry[1];
        const formattedDeals = [];

        const dealsByTimeSlotsSorted = new Map(
            [...dealsByTimeSlots.entries()].sort((a, b) => {
                if (a[0].startTime !== b[0].startTime) {
                    return a[0].startTime - b[0].startTime;
                }
                return a[0].endTime - b[0].endTime;
            })
        );

        let first = true;
        for (let [timeSlot, deals] of dealsByTimeSlotsSorted.entries()) {
            const formattedTimeSlot = getFormattedStrippedTimeSlots(timeSlot);

            const dealsToShow = [];
            deals
                .sort((deal1, deal2) => {
                    if (
                        deal1.PriceAfter.startsWith("$") &&
                        deal2.PriceAfter.startsWith("$")
                    ) {
                        const deal1Price = parseFloat(
                            deal1.PriceAfter.substr(1)
                        );
                        const deal2Price = parseFloat(
                            deal2.PriceAfter.substr(1)
                        );
                        return deal1Price - deal2Price;
                    }
                    if (!deal1.PriceAfter.startsWith("$")) {
                        return -1;
                    }
                    if (!deal2.PriceAfter.startsWith("$")) {
                        return 1;
                    }
                    return deal1.PriceAfter <= deal2.PriceAfter ? -1 : 1;
                })
                .forEach((deal) => {
                    let dealToShow = (
                        <div className="deal-description">
                            <div className="price-after">{deal.PriceAfter}</div>
                            <div className="description">
                                {deal.Description}
                                {priceBefore && (
                                    <span className="price-before">
                                        {deal.PriceBefore}
                                    </span>
                                )}
                            </div>
                        </div>
                    );
                    dealsToShow.push(dealToShow);
                });
            formattedDeals.push(
                <div
                    className={first ? "deals-details first" : "deals-details"}
                >
                    <div className="left">
                        <p className="fas fa-clock timing-icon" />
                    </div>
                    <div className="right">
                        <div className="time-slots">{formattedTimeSlot}</div>
                        <div className="deal-descriptions">{dealsToShow}</div>
                    </div>
                </div>
            );
            first = false;
        }
        formattedDealsByWeek[dayOfWeek] = (
            <div className="biz-detail-deals">{formattedDeals}</div>
        );
    });
    return formattedDealsByWeek;
}

export function priceIndicator(priceLevel) {
    let priceIcons = "";
    for (let i = 0; i < priceLevel; i++) {
        priceIcons += "$";
    }
    return priceIcons;
}

export function replaceImageUrlS3ToCF(business) {
    if (business.Photos) {
        for (let i = 0; i < business.Photos.length; i++) {
            business.Photos[i].Url = business.Photos[i].Url.replace(
                "dealiem-business-photos.s3.us-east-2.amazonaws.com",
                "d1rr8phqyp2cz1.cloudfront.net"
            );
            if (!isChrome && !isChromium) {
                business.Photos[i].Url += ".jpg";
            }
        }
    }
}

export function overrideTimeSlotsWithBusinessHours(business) {
    // overriding timeslots with business opening hours
    // 3 cases:
    // timeslot has both open and close hours overriden
    // timeslot has only open hour overriden
    // timeslot has only close hour overriden
    // becuase timeslot can have multiple days with
    // each day having a differnet business open and close hour
    // the timeslots are mapped to a new set after overriding and deduping
    // original timeslots:
    // 1,2 : 1500, 1800
    // 3 : 1200, 1400
    // business timeslots:
    // 1: 1000, 1800
    // 2: 1200, 1800
    // 3: 1000, 1800
    // after overriding and deduping:
    // 1,3: 1000, 1800
    // 2: 1200, 1800

    if (business.TimeSlots) {
        const businessTimeSlots = business.TimeSlots;

        for (let i = 0; i < business.Deals.length; i++) {
            const deal = business.Deals[i];
            const uniqueTimeSlots = {};

            for (let j = 0; j < deal.TimeSlots.length; j++) {
                const dealTimeSlot = deal.TimeSlots[j];

                if (dealTimeSlot.StartAsOpen && dealTimeSlot.EndAsClose) {
                    dealTimeSlot.DaysOfWeek.forEach((day) => {
                        businessTimeSlots.forEach((businessTimeSlot) => {
                            if (day === businessTimeSlot.DayOfWeek) {
                                const currTimeSlot = [
                                    businessTimeSlot.StartTime,
                                    businessTimeSlot.EndTime,
                                ];
                                if (uniqueTimeSlots[currTimeSlot]) {
                                    uniqueTimeSlots[
                                        currTimeSlot
                                    ].DaysOfWeek.push(day);
                                } else {
                                    uniqueTimeSlots[currTimeSlot] = {
                                        StartTime: currTimeSlot[0],
                                        EndTime: currTimeSlot[1],
                                        DaysOfWeek: [day],
                                    };
                                }
                            }
                        });
                    });
                } else if (dealTimeSlot.StartAsOpen) {
                    dealTimeSlot.DaysOfWeek.forEach((day) => {
                        businessTimeSlots.forEach((businessTimeSlot) => {
                            if (day === businessTimeSlot.DayOfWeek) {
                                const currTimeSlot = [
                                    businessTimeSlot.StartTime,
                                    dealTimeSlot.EndTime,
                                ];
                                if (uniqueTimeSlots[currTimeSlot]) {
                                    uniqueTimeSlots[
                                        currTimeSlot
                                    ].DaysOfWeek.push(day);
                                } else {
                                    uniqueTimeSlots[currTimeSlot] = {
                                        StartTime: currTimeSlot[0],
                                        EndTime: currTimeSlot[1],
                                        DaysOfWeek: [day],
                                    };
                                }
                            }
                        });
                    });
                } else if (dealTimeSlot.EndAsClose) {
                    dealTimeSlot.DaysOfWeek.forEach((day) => {
                        businessTimeSlots.forEach((businessTimeSlot) => {
                            if (day === businessTimeSlot.DayOfWeek) {
                                const currTimeSlot = [
                                    dealTimeSlot.StartTime,
                                    businessTimeSlot.EndTime,
                                ];
                                if (uniqueTimeSlots[currTimeSlot]) {
                                    uniqueTimeSlots[
                                        currTimeSlot
                                    ].DaysOfWeek.push(day);
                                } else {
                                    uniqueTimeSlots[currTimeSlot] = {
                                        StartTime: currTimeSlot[0],
                                        EndTime: currTimeSlot[1],
                                        DaysOfWeek: [day],
                                    };
                                }
                            }
                        });
                    });
                } else {
                    const currTimeSlot = [
                        dealTimeSlot.StartTime,
                        dealTimeSlot.EndTime,
                    ];
                    uniqueTimeSlots[currTimeSlot] = {
                        StartTime: currTimeSlot[0],
                        EndTime: currTimeSlot[1],
                        DaysOfWeek: dealTimeSlot.DaysOfWeek,
                    };
                }
            }

            if (Object.keys(uniqueTimeSlots).length > 0) {
                const updatedTimeSlots = [];
                Object.keys(uniqueTimeSlots).forEach((timeSlot) => {
                    updatedTimeSlots.push(uniqueTimeSlots[timeSlot]);
                });
                deal.TimeSlots = updatedTimeSlots;
            }
        }
    }
}
