export function decrypt(json) {
    const output = [];
    const key = atob(getKey());
    for (let i = 0; i < json.length; i++) {
        const charCode = json.charCodeAt(i) ^ key[i % key.length].charCodeAt(0);
        output.push(String.fromCharCode(charCode));
    }
    return output.join("");
}

export function getKey() {
    return "ZGVhbGllbQ==";
}
