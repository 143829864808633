import * as React from "react";
import "../css/banner.css";
import connect from "react-redux/lib/connect/connect";
import { closeNotification } from "../store/actionCreator";

const mapStateToProps = (state) => {
    return {
        showNotification: state.messages.showNotification,
    };
};

class BannerNotification extends React.Component {
    closeMessage = () => {
        this.props.dispatch(closeNotification());
    };

    render() {
        return this.props.showNotification ? (
            <div className="banner-msg notification is-info is-light">
                <button className="delete" onClick={this.closeMessage} />
                Please check with venue directly as hours and deals can change.
             
            </div>
        ) : (
            <div />
        );
    }
}

export default connect(mapStateToProps)(BannerNotification);
