import React from "react";
import BusinessCard from "./businessCard";
import { filterBusiness } from "../utils/filterBusiness";
import noDealsImg from "../images/no-deals-researching.png";
import { calculateDistance } from "../utils/geoDistanceUtils";
import { hoverPin, saveScrollPosition } from "../store/actionCreator";

class Business extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        console.log(
            "to scroll:" +
                this.props.scroll.documentPosition +
                "," +
                this.props.scroll.listPosition
        );
        if (this.props.scroll) {
            window.scrollBy(
                0,
                this.props.scroll.documentPosition - window.pageYOffset
            );
        }

        if (
            document.getElementsByClassName("business-list")[0] &&
            this.props.scroll
        ) {
            document.getElementsByClassName(
                "business-list"
            )[0].scrollTop = this.props.scroll.listPosition;
        }
    }

    componentWillUnmount() {
        this.props.dispatch(
            saveScrollPosition(
                window.pageYOffset,
                document.getElementsByClassName("business-list")[0]
                    ? document.getElementsByClassName("business-list")[0]
                          .scrollTop
                    : 0
            )
        );
    }

    businessDivs(business, clickedId) {
        return business
            .sort((business1, business2) => {
                if (clickedId === business1.id) {
                    return -1;
                } else if (clickedId === business2.id) {
                    return 1;
                }

                if (
                    this.props.location.lat &&
                    this.props.location.lon &&
                    this.props.location.lat !== -1
                ) {
                    let distance1 = calculateDistance(
                        this.props.location.lat,
                        this.props.location.lon,
                        business1.Lat,
                        business1.Lon
                    );
                    let distance2 = calculateDistance(
                        this.props.location.lat,
                        this.props.location.lon,
                        business2.Lat,
                        business2.Lon
                    );
                    return distance1 - distance2;
                } else {
                    return (
                        parseFloat(business2.Rating) -
                        parseFloat(business1.Rating)
                    );
                }
            })
            .map((business) => {
                let distance = -1;

                if (
                    this.props.location.lat &&
                    this.props.location.lon &&
                    this.props.location.lat !== -1
                ) {
                    distance = calculateDistance(
                        this.props.location.lat,
                        this.props.location.lon,
                        business.Lat,
                        business.Lon
                    );
                }

                let photosToUse = business.Photos.filter((photo) => {
                    // vertical photos are looking better even on desktop so using ForPhone = true
                    return photo.ForPhone === true;
                });

                if (photosToUse.length === 0) {
                    photosToUse = business.Photos;
                }

                return (
                    <BusinessCard
                        name={business.Name}
                        img={photosToUse[0].Url}
                        rating={business.Rating.toFixed(1)}
                        ratingCount={business.RatingCount}
                        priceLevel={business.PriceLevel}
                        deals={business.Deals}
                        id={business.id}
                        isClicked={business.id === clickedId}
                        distance={distance}
                        features={business.Features}
                        onMouseEnter={() => {
                            this.props.dispatch(hoverPin(business));
                        }}
                        onMouseLeave={() => {
                            this.props.dispatch(hoverPin(null));
                        }}
                        outsideFilter={business.outsideFilter}
                    />
                );
            });
    }

    render = () => {
        if (this.props.fetchBusiness !== "loaded") {
            return <div className="column deals-col" />;
        }

        const clickedId =
            this.props.clickedBusiness != null && this.props.clickedBusiness.id;

        let businesses = filterBusiness(
            this.props.business,
            this.props.filters,
            this.props.location
        );
        let business1 = this.businessDivs(businesses[0], clickedId);
        let business2 = this.businessDivs(businesses[1], clickedId);

        let business2Section =
            business2.length > 0 ? (
                <div>
                    <div className="outside-search">
                        <p>DEALS OUTSIDE YOUR SEARCH CRITERIA</p>
                    </div>
                    {business2}
                </div>
            ) : (
                <div />
            );

        return business1.length > 0 || business2.length > 0 ? (
            <div className="column deals-col">
                <div className="business-list">
                    {business1}
                    {business2Section}
                </div>
            </div>
        ) : (
            <div className="column empty-business">
                <img className="no-deals-img" src={noDealsImg} />
                <div className="p1">
                    <p>Sorry, no deals found</p>
                    <p>Try to expand your search criteria</p>
                </div>
                <div className="p2">
                    <p>Know a deal you want to share?</p>
                    <a href="mailto:hello@dealiem.com" target="blank">
                        <button className="button is-rounded send-deal-btn">
                            Send us a deal
                        </button>
                    </a>
                </div>
            </div>
        );
    };
}

export default Business;
