import { findRanges } from "./daysUtil";
import _ from "lodash";
import { overrideTimeSlotsWithBusinessHours } from "./businessUtil";

const cloneDeep = require("clone-deep");

function dayMatchesTimeSlot(timeSlot, daysArr) {
    let diff = daysArr.filter((day) => timeSlot.DaysOfWeek.includes(day));
    return diff.length === daysArr.length;
}

function timeMatchesTimeSlot(timeSlot, time) {
    let startTime = timeSlot.StartTime;
    let endTime = timeSlot.EndTime;

    if (startTime > endTime) {
        endTime += 2400;
    }

    return time >= startTime && time < endTime;
}

function applyDayTimeFilter(deal, day, time) {
    if (!day && !time) {
        return true;
    }

    if (!deal || !deal.TimeSlots || deal.TimeSlots.length === 0) {
        return false;
    }

    let daysArr;
    if (day === "all") {
        daysArr = [1, 2, 3, 4, 5, 6, 7];
    } else {
        daysArr = [parseInt(day)];
    }

    let matches = false;
    deal.TimeSlots.forEach((timeSlot) => {
        if (timeSlot) {
            matches =
                matches ||
                ((!day || dayMatchesTimeSlot(timeSlot, daysArr)) &&
                    (!time || timeMatchesTimeSlot(timeSlot, time)));
        }
    });

    return matches;
}

function applyDayTimeFilterTimeSlots(timeSlots, day, time) {
    if (timeSlots == null) {
        return timeSlots;
    }

    if (!day && !time) {
        return timeSlots;
    }

    if (!timeSlots || timeSlots.length === 0) {
        return timeSlots;
    }

    let daysArr;
    if (day === "all") {
        daysArr = [1, 2, 3, 4, 5, 6, 7];
    } else {
        daysArr = [parseInt(day)];
    }

    return timeSlots.filter((timeSlot) => {
        if (timeSlot) {
            return (
                (!day || dayMatchesTimeSlot(timeSlot, daysArr)) &&
                (!time || timeMatchesTimeSlot(timeSlot, time))
            );
        }
        return false;
    });
}

function applyRatingFilter(business, rating) {
    if (!rating) {
        return true;
    }
    rating = parseInt(rating);
    return business && business.Rating && business.Rating >= rating;
}

function applyNeighborhoodFilter(business, neighborhood) {
    return true;
}

function applyCategoriesFilter(deal, category) {
    if (!category) {
        return true;
    }
    if (!deal.Categories) {
        return true;
    }
    return deal.Categories.indexOf(parseInt(category)) >= 0;
}

function applySearchFilterDeal(deal, search) {
    if (!search) {
        return true;
    }
    const parts = search.split("||");
    for (const part of parts) {
        if (!part || deal.Description.toLowerCase().includes(part)) {
            return true;
        }
    }
    return false;
}

function applySearchFilterBusiness(business, search) {
    if (!search) {
        return true;
    }
    const parts = search.split("||");
    for (const part of parts) {
        if (!part || business.Name.toLowerCase().includes(part)) {
            return true;
        }
    }
    return false;
}

export function filterBusiness(businesses, filters, location = null) {
    if (!businesses) {
        return [];
    }

    businesses = cloneDeep(
        businesses.filter((business) => {
            // hiding ones with no deals
            let show =
                business.Deals &&
                business.Deals.length > 0 &&
                // hiding ones with none of deals with a valid timeslot
                business.Deals.filter((deal) => {
                    return deal.TimeSlots && deal.TimeSlots.length > 0;
                }).length > 0 &&
                // hiding ones with no photos
                business.Photos &&
                business.Photos.length > 0 &&
                // hide ones with no rating
                business.Rating;
            if (!show) {
                console.log("filtered business:" + business.Id, business.Name);
            }
            return show;
        })
    );

    let businessWithinLocation = [];
    if (location && location.sw && location.ne) {
        let sw = location.sw;
        let ne = location.ne;
        businessWithinLocation = businesses.filter((business) => {
            return (
                business.Lat >= sw.lat &&
                business.Lat <= ne.lat &&
                business.Lon >= sw.lon &&
                business.Lon <= ne.lon
            );
        });
    } else {
        businessWithinLocation = businesses;
    }

    let filteredBusiness = businessWithinLocation.filter((business) => {
        return (
            applyRatingFilter(business, filters.rating) &&
            applyNeighborhoodFilter(business, filters.neighborhood)
        );
    });

    let filteredDealBusiness = filteredBusiness.filter((business) => {
        const businessMatchesSearch = applySearchFilterBusiness(
            business,
            filters.search
        );
        if (business.Deals) {
            // this is very critical. this logic overrides the business hours
            // on deals timeslots. unfortunately this needs to go here because
            // it needs to be done before day filters are applied but also
            // after the empty deal cleanups etc are done.
            overrideTimeSlotsWithBusinessHours(business);

            const validDeals = [];
            business.Deals.forEach((deal) => {
                // we hack timeslots to drop by using a 0 in days.
                // this will be not needed once the deletion function
                // is available.
                if (deal.TimeSlots) {
                    deal.TimeSlots = deal.TimeSlots.filter((timeSlot) => {
                        return timeSlot && !_.isEqual(timeSlot.DaysOfWeek, [0]);
                    });
                }
                const validTimeSlots = applyDayTimeFilterTimeSlots(
                    deal.TimeSlots,
                    filters.day,
                    filters.time
                );
                if (
                    validTimeSlots &&
                    validTimeSlots.length > 0 &&
                    applyCategoriesFilter(deal, filters.category) &&
                    (businessMatchesSearch ||
                        applySearchFilterDeal(deal, filters.search))
                ) {
                    deal.TimeSlots = validTimeSlots;
                    validDeals.push(deal);
                }
            });
            if (validDeals.length > 0) {
                business.Deals = validDeals;
                return true;
            }
        }
        return false;
    });

    let notFilteredDealBusiness = filteredBusiness.filter((business) => {
        return (
            filteredDealBusiness.indexOf(business) < 0 &&
            business.Deals &&
            business.Deals.length > 0
            // business.Deals.map(deals => {
            //     deals.forEach(deal => {
            //         if (!deal.TimeSlots || deal.TimeSlots.length === 0) {
            //             return false;
            //         }
            //     })
            // })
        );
    });

    notFilteredDealBusiness.forEach((business) => {
        business["outsideFilter"] = true;
    });

    return [filteredDealBusiness, notFilteredDealBusiness];
}
