function toRad(Value) {
    /** Converts numeric degrees to radians */
    return (Value * Math.PI) / 180;
}

export function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // metres
    const the1 = toRad(lat1);
    const the2 = toRad(lat2);
    const delThe = toRad(lat2 - lat1);
    const delGamma = toRad(lon2 - lon1);

    const a =
        Math.sin(delThe / 2) * Math.sin(delThe / 2) +
        Math.cos(the1) *
            Math.cos(the2) *
            Math.sin(delGamma / 2) *
            Math.sin(delGamma / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return (R * c).toFixed(1);
}
