import React, { Component } from "react";
import { applyMiddleware, createStore } from "redux";
import reducers from "../store/reducer";
import Provider from "react-redux/lib/components/Provider";
import { updateMarket, initializeMap } from "../store/actionCreator";
import thunkMiddleware from "redux-thunk";
import Home from "./home";
import "../css/util.css";
import ReactGA from "react-ga";
import { isMobile } from "react-device-detect";

import { BrowserRouter } from "react-router-dom";

const store = createStore(reducers, applyMiddleware(thunkMiddleware));

class Main extends Component {
    componentDidMount() {
        // Toronto Downtown
        const defaultLat = 43.6532;
        const defaultLon = -79.3832;

        store.dispatch(updateMarket("gta"));
        // defaults to Downtown Toronto
        // store.dispatch(changeLocation(defaultLat, defaultLon));
        store.dispatch(initializeMap());

        // if (navigator.geolocation) {
        //     navigator.geolocation.getCurrentPosition(
        //         (pos) => {
        //             store.dispatch(
        //                 changeLocation(
        //                     pos.coords.latitude,
        //                     pos.coords.longitude
        //                 )
        //             );
        //             store.dispatch(initializeMap());
        //         },
        //         (pos) => {
        //             console.log("error in fetching location");
        //         },
        //         {
        //             enableHighAccuracy: false,
        //             timeout: 5000,
        //             maximumAge: 60000,
        //         }
        //     );
        // }
        this.initAnalytics();
    }

    initAnalytics() {
        ReactGA.initialize("UA-151890897-1");
        ReactGA.set({ isMobile: isMobile });
    }

    render() {
        return (
            <Provider store={store}>
                <BrowserRouter>
                    <Home />
                </BrowserRouter>
            </Provider>
        );
    }
}

export default Main;
