import React from "react";
import MainContainer from "./mainContainer";

class MainSection extends React.Component {
    render() {
        return (
            <section className="main-section">
                <div className="container">
                    <MainContainer />
                </div>
            </section>
        );
    }
}

export default MainSection;
